import React, { Component } from "react";

import "./HomePage.scss";
import { Link } from "react-router-dom";
import HomeImage from "../../assets/icons/self-portrait.jpg";
import MailBox from "../../components/MailBox";
import ReactGA from "react-ga";

class HomePage extends Component {
  handleResumeDownload = () => {
    ReactGA.event({
      category: "Outgoing",
      action: "Download Resume",
    });
    window.location.href =
      "https://github.com/akhilesh-k/akhilesh-k.github.io/raw/master/Resume.pdf";
  };

  render() {
    const blogPosts = [
      {
        date: "Aug 19, 2024",
        title: "On learning deeply",
        link: "essays/back-to-learning-deeply",
      },
      {
        date: "Jul 31, 2024",
        title: "Why I run?",
        link: "essays/how-does-the-hnsw-algorithm-work",
      },
      {
        date: "Jul 29, 2024",
        title: "I started reading, again!",
        link: "essays/i-started-reading-again",
      },
      {
        date: "Jul 08, 2024",
        title: "When Priorities Change",
        link: "essays/how-does-the-hnsw-algorithm-work",
      },
      {
        date: "Feb 09, 2024",
        title: "Marking the 100k ride off my bucketlist",
        link: "essays/how-does-the-hnsw-algorithm-work",
      },
    ];

    const papers = [
      {
        date: "Aug 19, 2024",
        title: "Attention Is All You Need",
        link: "https://arxiv.org/pdf/1706.03762",
      },
      {
        title: "ROSE: Robust Caches for Amazon Product Search",
        link:
          "https://assets.amazon.science/dc/5e/b919974a4abdba7a3cd82c1bc86f/rose-robust-caches-for-amazon-product-search.pdf",
      },
      {
        title: "Bkd-Tree: A Dynamic Scalable kd-Tree",
        link:
          "https://users.cs.duke.edu/~pankaj/publications/papers/bkd-sstd.pdf",
      },
    ];

    return (
      <div className="HomePage">
        <div className="home-section">
          <div id="jumbo">
            {/* <img src={wave} alt="wave"></img> */}
            <h1 className="title serif">Hi, I'm Akhilesh!</h1>
            <div className="subtitle">
              <p>
                Hi, I’m a software engineer with a strong passion for Natural
                Language Processing, Information Retrieval, Distributed Systems,
                Database Internals, and Language Internals.
              </p>
              <p>
                I joined{" "}
                <Link
                  to={{
                    pathname: "https://target.com",
                  }}
                  target="_blank"
                >
                  {" "}
                  Target's
                </Link>{" "}
                Relevancy Team as Senior Engineer where I build and optimize
                systems to solve complex relevancy challenges.{" "}
              </p>
              <p>
                Before this, I contributed to
                <Link
                  to={{
                    pathname: "https://blibli.com",
                  }}
                  target="_blank"
                >
                  {" "}
                  Blibli.com's
                </Link>{" "}
                search platform, focusing on product rankings and developing
                efficient indexing and retrieval pipelines that powered their
                search engine at scale (20 Mn+ unique searches).
              </p>
              <p>
                I read, (<Link to="essays/i-started-reading-again">a lot</Link>
                ). Follow me on&nbsp;
                <Link
                  to={{
                    pathname:
                      "https://www.goodreads.com/user/show/129681320-akhilesh-kumar",
                  }}
                  target="_blank"
                >
                  goodreads
                </Link>
                , show me what you read too! Apart from the passion of
                engineering the softwares I have a keen interest in{" "}
                <Link to="/running">Running</Link>, Biking and Swimming. I often
                write about my athletic journey, sharing insights and
                experiences along the way.
              </p>
              <p>
                I also enjoy diving deep into the engineering process and share
                my discoveries in <Link to="/essays">weekly essays</Link>, where
                I reflect on everything from technical details to lessons
                learned.
              </p>
            </div>
            {/* <button
              align="center"
              className="button is-black is-rounded"
              onClick={this.handleResumeDownload}
            >
              Download My Resume
            </button> */}
            {/* <div className="linkbar"> */}
            {/* <Link to="/essays">essays</Link> */}
            {/* <Link to="/timeline">timeline</Link> */}
            {/* <Link to="/bookshelf">bookshelf</Link> */}
            {/* <Link to="/papershelf">papershelf</Link> */}
            {/* <Link to="/about-akhilesh-kumar">whoami</Link> */}
            {/* <Link to="/resume">resume</Link> */}
            {/* </div> */}
            <div className="mailbox">
              <MailBox />
            </div>
          </div>
          <div id="jumbo j-img">
            <img
              src={HomeImage}
              alt=""
              style={{
                borderRadius: "2em",
                width: "400px",
              }}
            />
          </div>
        </div>
        <div className="home-section">
          <div id="jumbo">
            {/* <img src={wave} alt="wave"></img> */}
            <p>
              <span className="recent serif">Recent blog posts </span>•
              <Link to="/essays"> Full archive ➔</Link>{" "}
            </p>

            <p>Things I have written recently.</p>
            <ul className="subtitle">
              {blogPosts.map((post, index) => (
                <li key={index}>
                  <span className="is-family-monospace is-family-code">
                    {post.date} :{" "}
                  </span>{" "}
                  <Link to={post.link}>{post.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div id="jumbo">
            {/* <img src={wave} alt="wave"></img> */}
            <p>
              <span className="recent serif">Recent papers </span>•
              <Link to="/papershelf"> Papershelf ➔</Link>{" "}
            </p>
            <p>Papers I have read recently.</p>
            <div className="subtitle">
              <ul className="subtitle">
                {papers.map((post, index) => (
                  <li key={index}>
                    <Link to={post.link}>{post.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* <Stack className="stack" /> */}
      </div>
    );
  }
}

export default HomePage;
