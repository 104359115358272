import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Essays.scss";

class Essays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      loading: true,
    };
  }

  componentDidMount() {
    fetch("https://raw.githubusercontent.com/akhilesh-k/blog-content/main/blogs.json")
      .then((response) => response.json())
      .then((data) => {
        const sections = this.transformDataToSections(data);
        this.setState({ sections, loading: false });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({ loading: false });
      });
  }

  transformDataToSections(data) {
    const categories = [...new Set(data.map((item) => item.category))];

    const sections = categories.map((category) => ({
      title: category,
      blogs: data.filter((item) => item.category === category),
    }));

    // Move sections with "WIP" in the title to the beginning
    sections.sort((a, b) => {
      if (a.title.includes("WIP")) return -1;
      if (b.title.includes("WIP")) return 1;
      return 0;
    });

    return sections;
  }

  handleTitleClick = (essayId) => {
    this.props.history.push(`/essays/${essayId}`);
  };

  renderBookSection = (section, clickable = true) => (
    <div className="column content" id={section.title.replace(/\s+/g, "-").toLowerCase()}>
      <h3 className="subtitle">{section.title}</h3>
      <ul>
        {section.blogs.map((blog, index) => (
          <li key={index}>
            <h6>
              <div
                className={`link-title ${clickable ? 'clickable' : ''}`}
                onClick={() => clickable && blog.title && this.handleTitleClick(blog.title.replace(/\s+/g, "-").toLowerCase())}
              >
                {blog.title}
              </div>
            </h6>
            <p>{blog.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );

  render() {
    const { sections, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="BlogPage">
        <div className="shelf-title">
          <h1 className="title serif">Essays and Blogs</h1>
          <p>Documenting my learnings through one essay at a time.</p>
        </div>
        <div className="columns">
          <div className="column is-one-quarter">
            <nav className="menu">
              <p className="menu-label">Sections</p>
              <ul className="menu-list">
                {sections.map((section, index) => (
                  <li key={index}>
                    <a href={`#${section.title.replace(/\s+/g, "-").toLowerCase()}`}>
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div className="columns">
            <div className="column">
              {sections.map((section, index) =>
                index % 2 !== 0 ? (
                  <div className="" key={index}>
                    {this.renderBookSection(section, true)}
                  </div>
                ) : null
              )}
            </div>
            <div className="column">
              {sections.map((section, index) =>
                index % 2 === 0 ? (
                  <div className="" key={index}>
                    {this.renderBookSection(section, true)}
                  </div>
                ) : null
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Essays);
