import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./BookShelf.scss";

class BookShelf extends Component {
  currentMonthInWords() {
    const date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    return month;
  }

  state = {
    sections: [],
  };

  componentDidMount() {
    const { location } = this.props;
    const shelfType = location.pathname.split("/").pop();
    if (shelfType === "bookshelf") {
      this.goodReadsData = Object.entries(localStorage)
        .map(([key]) => key)
        .filter((key) => key.includes("goodreadsdata"))
        .map((key) => {
          const month = key.split("-")[1];
          if (month === this.currentMonthInWords()) {
            const storedData = localStorage.getItem(key);
            return JSON.parse(storedData);
          }
          return null;
        })
        .filter((data) => data !== null);

      if (this.goodReadsData && this.goodReadsData.length > 0) {
        const sections = this.goodReadsData[0];
        this.setState({ sections });
      } else {
        this.fetchBookDataFromSheet();
      }
    }
  }
  async fetchBookDataFromSheet() {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const spreadsheetId = process.env.REACT_APP_SPREADSHEET_ID;
    const range = "Sheet1!A2:Y582";
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Process the data and update state
      const sections = this.processSheetData(data.values);
      this.setState({ sections });
    } catch (error) {
      console.error("Error fetching data from Google Sheets:", error);
    }
  }

  processSheetData(data) {
    const sections = [];
    const excludeColumns = [];  // Add logic for excluding specific columns if necessary
  
    data.forEach((row) => {
      // Filter out unwanted columns
      const filteredRow = row.filter((_, index) => !excludeColumns.includes(index));
  
      // Check if the row has a 'read' or 'currently-reading' shelf (column 18)
      if (filteredRow[0] && filteredRow[18] && (filteredRow[18] === "read" || filteredRow[18] === "currently-reading")) {
        
        // Check if this section already exists
        let currentSection = sections.find(section => section.title === filteredRow[24]);
        
        // If section doesn't exist, create a new one
        if (!currentSection) {
          currentSection = {
            title: filteredRow[24], // Section title
            books: [],
          };
          sections.push(currentSection);
        }
        console.log(filteredRow)
        
        // Add the book to the current section
        currentSection.books.push({
          title: filteredRow[1],         // Book title
          author: filteredRow[2],        // Author
          description: filteredRow[5],   // Description
          shelf: filteredRow[18],        // Shelf (read or currently-reading)
          review: filteredRow[19],       // Review
          rating: filteredRow[3],        // Rating (adjust if necessary)
          startDate: filteredRow[15],    // Start date
          finishDate: filteredRow[14],   // Finish date
          section: filteredRow[24],      // Section title
        });
      }
    });
  
    console.log(sections);
  
    // Store serialized sections in localStorage
    if (sections.length > 0) {
      localStorage.setItem(
        `goodreadsdata-${this.currentMonthInWords()}`,
        JSON.stringify(sections)
      );
    }
  
    return sections;
  }

  renderBookSection(title, books) {
    if (!title || !books) return null;

    return (
      <div
        className="column content"
        id={title.replace(/\s+/g, "-").toLowerCase()}
      >
        <h3 className="subtitle">
          {title} ({books.length})
        </h3>
        <ul>
          {books.map((book, index) => (
            <li key={index}>
              <h6>
                <a rel="noopener noreferrer" href={book.link} target="_blank">
                  {book.title}
                </a>
              </h6>
              <p>{book.author}</p>
              {book.shelf === "currently-reading" ? (
                <p>
                  Started Reading on {book.startDate}
                </p>
              ) : (
                <p>Finished on: {book.finishDate}</p>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    const { location } = this.props;
    const { sections } = this.state;
    const shelfType = location.pathname.split("/").pop();

    // Default sections if none are loaded
    const defaultSections = [
      {
        title: "Currently Reading",
        books: [],
      },
    ];

    // Use sections from state if available, otherwise use defaults
    const displaySections = sections.length > 0 ? sections : defaultSections;

    const title = shelfType === "bookshelf" ? "Bookshelf" : "Papershelf";
    const shelfDescription =
      shelfType === "bookshelf"
        ? "I read a few books every month around various topics and here are some of them that I recommend."
        : "I try to read a paper every month around various topics and here are some of them that I implement or try to dissect.";

    // Group sections into rows of 2 for rendering
    const groupedSections = [];
    for (let i = 0; i < displaySections.length; i += 2) {
      groupedSections.push(displaySections.slice(i, i + 2));
    }

    return (
      <div className="BookShelf">
        <div className="shelf-title">
          <h1 className="title serif">{title}</h1>
          <p>{shelfDescription}</p>
        </div>
        <div className="columns">
          <div className="column is-one-quarter">
            <nav className="menu">
              <p className="menu-label">Sections</p>
              <ul className="menu-list">
                {displaySections.map((section, index) => (
                  <li key={index}>
                    <a
                      href={`#${section.title
                        .replace(/\s+/g, "-")
                        .toLowerCase()}`}
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="column">
            {displaySections.length > 0 && displaySections[0] && (
              <div className="columns">
                {this.renderBookSection(
                  displaySections[0].title,
                  displaySections[0].books
                )}
              </div>
            )}
            {groupedSections.slice(1).map((row, rowIndex) => (
              <div className="columns" key={rowIndex}>
                {row.map((section, index) => (
                  <React.Fragment key={index}>
                    {this.renderBookSection(section.title, section.books)}
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BookShelf);
