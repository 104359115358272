import CodePage from "./pages/CodePage"; // Import your page components
import Essays from "./pages/Essays";
import HomePage from "./pages/HomePage";
import TimelinePage from "./pages/TimelinePage";
import WhoamiPage from "./pages/WhoamiPage";
import BookShelf from "./pages/BookShelf";
import PaperShelf from "./pages/BookShelf";
import PostPage from "./pages/PostPage";
import Resume from "./pages/Resume";
import Running from "./pages/Running";

const pages = [
  {
    path: "/work",
    component: CodePage,
    exact: true,
  },
  {
    path: "/essays",
    component: Essays,
    exact: true,
    title: "Essays and Blogs | Akhilesh K",
  },
  {
    path: "/biking",
    component: Running,
    exact: true,
    title: "Tales on Wheels",
  },
  {
    path: "/running",
    component: Running,
    exact: true,
    title: "Running my heart out",
  },
  {
    path: "/",
    component: HomePage,
    exact: true,
    title: "Akhilesh K",
  },
  {
    path: "/timeline",
    component: TimelinePage,
    exact: true,
  },
  {
    path: "/bookshelf",
    component: BookShelf,
    exact: true,
  },
  {
    path: "/papershelf",
    component: PaperShelf,
    exact: true,
  },
  {
    path: "/resume",
    component: Resume,
    exact: true,
  },
  {
    path: "/about-akhilesh-kumar",
    component: WhoamiPage,
    exact: true,
    title: "About Akhilesh K",
  },
  {
    path: "/essays/:id",
    component: PostPage,
    exact: true,
  },
];
export default pages;
